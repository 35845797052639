import { DateTime, Duration } from "luxon";

import {
  ACCESS_TYPE,
  AUDITOR_POLICY_ID,
  DELTA_LAKE_ACCESS_TYPE,
  IMPERSONATION_APPROVER_POLICY_ID,
  L2_DATA_APPROVER_POLICY_ID,
  L3_DATA_APPROVER_POLICY_ID,
  PGPARK_ACCESS_TYPE,
  REQUEST_ACTIONS,
  REQUEST_JANITOR_USER_ID,
  REQUEST_STATUS,
  SESSION_STORAGE_IDENTITY_KEY,
} from "./constants";

export const getUserToken = () => {
  const identity = sessionStorage.getItem(SESSION_STORAGE_IDENTITY_KEY);
  if (identity === null) {
    throw new Error("No identity in session storage");
  }
  const { token } = JSON.parse(identity);
  return token;
};

export const getUserId = () => {
  const identity = sessionStorage.getItem(SESSION_STORAGE_IDENTITY_KEY);
  if (identity === null) {
    return null;
  }
  const { entityId = null } = JSON.parse(identity);
  return entityId;
};

export const getUserPolicies = () => {
  const identity = sessionStorage.getItem(SESSION_STORAGE_IDENTITY_KEY);
  if (identity === null) {
    return null;
  }
  const { policies = [] } = JSON.parse(identity);
  return policies;
};

export const userIsApprover = () => {
  const policies = getUserPolicies();
  if (policies === null) {
    return false;
  }
  return (
    policies.includes(IMPERSONATION_APPROVER_POLICY_ID) ||
    policies.includes(L2_DATA_APPROVER_POLICY_ID) ||
    policies.includes(L3_DATA_APPROVER_POLICY_ID)
  );
};

export const userIsAuditor = () => {
  const policies = getUserPolicies();
  if (policies === null) {
    return false;
  }
  return policies.includes(AUDITOR_POLICY_ID);
};

/* Convert a duration in seconds to a description string of weeks, days, hours, and/or minutes.
 */
export const formatDuration = (seconds) => {
  const dur = Duration.fromObject({ seconds });
  const { weeks, days, hours, minutes } = dur
    .shiftTo("weeks", "days", "hours", "minutes")
    .toObject();

  const formatted = [];
  if (weeks > 1) {
    formatted.push(`${weeks} weeks`);
  } else if (weeks === 1) {
    formatted.push(`${weeks} week`);
  }
  if (days > 1) {
    formatted.push(`${days} days`);
  } else if (days === 1) {
    formatted.push(`${days} day`);
  }
  if (hours > 1) {
    formatted.push(`${hours} hours`);
  } else if (hours === 1) {
    formatted.push(`${hours} hour`);
  }
  if (minutes > 1) {
    formatted.push(`${minutes} minutes`);
  } else if (minutes === 1) {
    formatted.push(`${minutes} minute`);
  }
  return formatted.join(", ");
};

/* Format a user object for display.
 */
export const formatUser = ({ id, name, email }, currentUserId = null) => {
  if (id === REQUEST_JANITOR_USER_ID) {
    return null;
  }
  if (id === currentUserId) {
    return (
      <>
        {name} (<strong>You</strong>)
      </>
    );
  }
  return `${name} (${email})`;
};

/* Format an access type for display.
 */
export const formatAccessType = (accessType) => {
  switch (accessType) {
    case ACCESS_TYPE.DELTA_LAKE:
      return "Delta Lake";
    case ACCESS_TYPE.IMPERSONATION:
      return "Impersonation";
    case ACCESS_TYPE.PGPARK:
      return "Database (pgpark)";
    default:
      return accessType;
  }
};

/* Format a request status for display.
 */
export const formatRequestStatus = (status) => {
  switch (status) {
    case REQUEST_STATUS.PENDING:
      return "Pending";
    case REQUEST_STATUS.APPROVED:
      return "Approved";
    case REQUEST_STATUS.REJECTED:
      return "Rejected";
    case REQUEST_STATUS.EXPIRED:
      return "Expired";
    case REQUEST_STATUS.AWAITING_L3_APPROVAL:
      return "Pending L3 Approval";
    case REQUEST_STATUS.CANCELED:
      return "Canceled";
    default:
      return status;
  }
};

/* Format a request action for display.
 */
export const formatRequestAction = (
  { action, actor, time },
  currentUserId = null
) => {
  const formattedActionTime = formatTimestamp(time);
  switch (action) {
    case REQUEST_ACTIONS.APPROVE:
      return (
        <>
          Approved by {formatUser(actor, currentUserId)} at&nbsp;
          {formattedActionTime}
        </>
      );
    case REQUEST_ACTIONS.REJECT:
      return (
        <>
          Rejected by {formatUser(actor, currentUserId)} at&nbsp;
          {formattedActionTime}
        </>
      );
    case REQUEST_ACTIONS.CREATE:
      return (
        <>
          Created by {formatUser(actor, currentUserId)} at&nbsp;
          {formattedActionTime}
        </>
      );
    case REQUEST_ACTIONS.EXPIRE:
      return `Expired at ${formattedActionTime}`;
    case REQUEST_ACTIONS.CANCEL:
      return (
        <>
          Canceled by {formatUser(actor, currentUserId)} at&nbsp;
          {formattedActionTime}
        </>
      );
    default:
      return null;
  }
};

/* Format a SQL timestamp for display.
 */
export const formatTimestamp = (timestamp) => {
  const dt = DateTime.fromSQL(timestamp, { zone: "utc" });
  return dt.toLocal().toFormat("ccc, DD, t ZZZZ");
};

/* Format an ISO timestamp for display.
 */
export const formatISOTimestamp = (timestamp) => {
  const dt = DateTime.fromISO(timestamp, { zone: "utc" });
  return dt.toLocal().toFormat("ccc, DD, t ZZZZ");
};

export const formatEnvironment = (environment) => {
  const [tenant, region, envName] = environment.split(":");
  switch (tenant) {
    case "chime":
      return `Chime ${envName} (${region})`;
    case "current":
      return `Current ${envName} (${region})`;
    case "multitenant":
      return `Multitenant ${envName} (${region})`;
    case "okex":
      return `OKX ${envName} (${region})`;
    case "salliemae":
      return `Sallie Mae ${envName} (${region})`;
    case "wealthsimple":
      return `Wealthsimple ${envName} (${region})`;
    default:
      return `${tenant} ${envName} (${region})`;
  }
};

/* Format a database access type for display.
 */
export const formatDatabaseAccessType = (accessType) => {
  switch (accessType) {
    case DELTA_LAKE_ACCESS_TYPE.TABLE:
    case PGPARK_ACCESS_TYPE.TABLE:
      return "Read/write access to tables";
    case PGPARK_ACCESS_TYPE.SUPERUSER:
      return "Superuser access";
    case PGPARK_ACCESS_TYPE.ADMIN:
      return "Administrative access";
    default:
      return accessType;
  }
};

/* This function accepts any number of lists of requests.
  If the current location contains a valid request ID hash (i.e. a request ID that is
  present in one of the provided lists of requests), return that ID. Otherwise, return
  the ID of the first request in the first list of requests.
 */
export const getTargetedRequestId = (...requestLists) => {
  const hashMatch = window.location.hash.match(/#request-(?<requestId>.*)/);
  if (hashMatch !== null) {
    for (const requests of requestLists) {
      if (
        requests.findIndex(
          (request) => request.id === hashMatch.groups.requestId
        ) !== -1
      ) {
        return hashMatch.groups.requestId;
      }
    }
  }
  for (const requests of requestLists) {
    if (requests.length) {
      return requests[0].id;
    }
  }
  return null;
};

/* This function accepts two objects, A and B, with a `name` attribute.
   It returns -1 if the name of A is ordered alphabetically before the name of B, 1 if
   the name of B is before the name of A, or 0 if they have the same name.
*/
export const sortByName = (A, B) => {
  if (A.name < B.name) return -1;
  if (A.name > B.name) return 1;
  return 0;
};

/* This function accepts two objects, A and B, with a `time` attribute.
   It returns -1 if the timestamp of A is ordered lexicographically before the timestamp
   of B, 1 if the timestamp of B is before the timestamp of A, or 0 if they have the same
   timestamp.
*/
export const sortByTime = (A, B) => {
  if (A.time < B.time) return -1;
  if (A.time > B.time) return 1;
  return 0;
};
