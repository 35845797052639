/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

// See https://designsystem.digital.gov/components/pagination/
function getPageItems({ currentPage, lastPage }) {
  const pageItems = [];

  // Show all pages if there are <= 7
  if (lastPage <= 7) {
    for (let page = 1; page <= lastPage; page++) {
      pageItems.push(page);
    }
    return pageItems;
  }

  // Show the previous page, current page, and next page
  if (currentPage > 1) {
    pageItems.push(currentPage - 1);
  }
  pageItems.push(currentPage);
  if (currentPage < lastPage) {
    pageItems.push(currentPage + 1);
  }

  // Show the first page
  if (pageItems[0] !== 1) {
    pageItems.unshift(1, "...");
  }
  // Show the last page
  if (pageItems.at(-1) !== lastPage) {
    pageItems.push("...", lastPage);
  }

  do {
    // Show more pages after the first overflow indicator, eg.
    // [1, ..., 6, 7, 8] => [1, ..., 5, 6, 7, 8] => [1, ..., 4, 5, 6, 7, 8]
    if (pageItems[1] === "...") {
      for (
        let page = pageItems[2] - 1;
        pageItems.length < 7 && page !== 1;
        page--
      ) {
        pageItems.splice(2, 0, page);
      }
      // Remove the first overflow indicator if it is not needed, eg.
      // [1, ..., 2, 3, 4, ..., 8] => [1, 2, 3, 4, ..., 8]
      // or [1, ..., 3, 4, 5 ..., 8] => [1, 2, 3, 4, 5, ..., 8]
      if (pageItems[2] - pageItems[0] === 1) {
        pageItems.splice(1, 1);
      } else if (pageItems[2] - pageItems[0] === 2) {
        pageItems.splice(1, 1, pageItems[0] + 1);
      }
    }

    // Show more pages before the second overflow indicator, eg.
    // [1, 2, 3, ..., 8] => [1, 2, 3, 4, ..., 8] => [1, 2, 3, 4, 5, ..., 8]
    if (pageItems.at(-2) === "...") {
      for (
        let page = pageItems.at(-3) + 1;
        pageItems.length < 7 && page !== lastPage;
        page++
      ) {
        pageItems.splice(-2, 0, page);
      }
      // Remove the second overflow indicator if it is not needed, eg.
      // [1, ..., 6, 7, 8, ..., 9] => [1, ..., 6, 7, 8, 9]
      // or [1, ..., 5, 6, 7, ..., 9] => [1, ..., 5, 6, 7, 8, 9]
      if (pageItems.at(-1) - pageItems.at(-3) === 1) {
        pageItems.splice(-2, 1);
      } else if (pageItems.at(-1) - pageItems.at(-3) === 2) {
        pageItems.splice(-2, 1, pageItems.at(-3) + 1);
      }
    }
  } while (pageItems.length < 7);

  return pageItems;
}

function Paginator({ ariaLabel, currentPage, lastPage, route }) {
  const [searchParams] = useSearchParams();
  searchParams.delete("page");

  if (lastPage <= 1) {
    return null;
  }

  const pageItems = getPageItems({ currentPage, lastPage });

  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const canNavToPrev = prevPage >= 1;
  const canNavToNext = nextPage <= lastPage;

  // Preserve existing search params
  const linkBase = searchParams.toString().length
    ? `${route}?${searchParams.toString()}&`
    : `${route}?`;

  return (
    <nav aria-label={ariaLabel}>
      <ul className="pagination mb-0 mt-2 justify-content-center">
        <li className="page-item">
          {canNavToPrev ? (
            <Link className="page-link" to={`${linkBase}page=${prevPage}`}>
              Previous
            </Link>
          ) : (
            <a className="page-link disabled" role="link" aria-disabled="true">
              Previous
            </a>
          )}
        </li>
        {pageItems.map((page, index) => {
          if (page === "...") {
            return (
              <li
                key={`omit-${index}`}
                className="page-item disabled"
                role="presentation"
              >
                <span className="page-link">...</span>
              </li>
            );
          }

          const ariaLabel =
            page === lastPage ? `last page, page ${page}` : `page ${page}`;
          const active = page === currentPage;
          return (
            <li
              key={page}
              className={active ? "page-item active" : "page-item"}
            >
              <Link
                to={`${linkBase}page=${page}`}
                className="page-link"
                aria-label={ariaLabel}
                aria-current={active ? "page" : "false"}
              >
                {page}
              </Link>
            </li>
          );
        })}
        <li className="page-item">
          {canNavToNext ? (
            <Link className="page-link" to={`${linkBase}page=${nextPage}`}>
              Next
            </Link>
          ) : (
            <a className="page-link disabled" role="link" aria-disabled="true">
              Next
            </a>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default Paginator;
